<template>
  <v-card class="py-0 my-0" rounded="10" v-bind="$attrs" :elevation="elevation">
    <!-- breadcrumbs -->
    <AppPageHeader v-if="breadcrumbs" />
    <v-card-title
      v-if="title"
      class="mb-4 text-h6 font-weight-bold black--text"
      >{{ title }}</v-card-title
    >
    <v-card-text>
      <template v-if="loading">
        <Loading :loading="loading" />
      </template>
      <template v-else>
        <slot name="content"></slot>
      </template>
      <v-card-actions>
        <slot name="actions"></slot>
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>

<script>
import Loading from './Loading'
import AppPageHeader from '@/components/core/AppPageHeader'

export default {
  name: 'VWidget',
  components: { Loading, AppPageHeader },
  props: {
    title: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'grey lighten-3'
    },
    dense: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    elevation: {
      type: [Number, String],
      default: 2
    },
    breadcrumbs: {
      type: Boolean,
      default: true
    },
    classNames: {
      type: String,
      default: ''
      // default: 'px-8 pt-4 pb-6'
    }
  }
}
</script>

<style scoped>
.custom-title {
  background-color: #f0f0f7 !important;
}
</style>
