<template>
  <v-widget :title="title" :loading="loading" :breadcrumbs="breadcrumbs" :elevation="elevation">
    <template #content>
      <ValidationObserver ref="observer" v-slot="{ invalid }">
        <form @submit.prevent="$emit('submit', $event)">
          <slot name="content"></slot>
          <v-divider class="my-4 my-md-8" />
          <v-card-actions
            class="d-flex justify-space-between justify-sm-end pr-0"
          >
            <slot name="footer-custom-button-before"></slot>
            <v-btn
              class="mx-md-2 btn-cancel button-responsive"
              outlined
              color="primary"
              large
              :x-large="$vuetify.breakpoint.mdAndUp"
              :x-small="$vuetify.breakpoint.smAndDown"
              @click="$router.go(-1)"
              rounded
              >Cancelar</v-btn
            >
            <v-btn
              class="ml-md-2 btn-submit"
              type="submit"
              elevation="6"
              rounded
              dark
              large
              :x-large="$vuetify.breakpoint.mdAndUp"
              :loading="loading"
              :disabled="invalid || loading || submitDisabled"
            >
              {{ textSave }}
            </v-btn>
            <slot name="footer-custom-button-after"></slot>
          </v-card-actions>
        </form>
      </ValidationObserver>
    </template>
  </v-widget>
</template>

<script>
import VWidget from './VWidget'
export default {
  name: 'BaseForm',
  components: { VWidget },
  props: {
    title: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    saving: {
      type: Boolean,
      default: false
    },
    textSave: {
      type: String,
      default: 'Guardar'
    },
    iconSave: {
      type: String,
      default: 'mdi-send'
    },
    width: {
      type: [String, Number],
      default: '100%'
    },
    elevation: {
      type: [Number, String],
      default: 2
    },
    breadcrumbs: {
      type: Boolean,
      default: true
    },
    submitDisabled: {
      type: Boolean,
      default: false
    },
  }
}
</script>

<style>

  @media only screen and (max-width: 450px) {
    .button-responsive {
      max-width: 100px !important;
      color: red;
    }
  }

</style>
